/*
 * Handles SDS Page localization redirects.
 *
 * Used to create pretty URL's that look like
 * {locale}/brands/safety/sds/{brandname}
 * to improve SEO.
 *
 */
export default function sdsPage() {
    $(document).ready(() => {
        function filterByOptionValues() {
            $('#filter-submit').on('click', (event) => {
                event.preventDefault();

                // get selected <options>
                let selectedRegion = $('#region   :selected').val();
                let selectedBrand = $('#category :selected')
                    .val()
                    .toLowerCase()
                    .replace(/[^\w\s]/gi, '');

                // pretty up brand names
                selectedRegion = selectedRegion.replace(' ', '');
                selectedBrand = selectedBrand.replace(/\s/g, '-');

                // redirect the page to a custom URL structure by obj literal key/value
                const localizationMapping = {
                    UnitedStates: 'en-us',
                    Canada: 'en-ca',
                    Europe: 'en-eu',
                    Japan: 'ja-jp',
                    Malaysia: 'en-my',
                    PuertoRico: 'en-pr',
                    Singapore: 'en-sg',
                    SouthKorea: 'ko-kr',
                };

                // Loop through object to look for abbreviated version of region
                let localeFound;
                $.each(localizationMapping, (key, value) => {
                    if (selectedRegion === key) {
                        localeFound = value;
                    }
                });

                // Handle Form Submit with prettified URL as action attrib
                const localizedPageRedirect = `/${localeFound}/brands/safety/sds/${selectedBrand}/`;
                $('#selectOptions').attr('action', localizedPageRedirect).submit();
            });
        }

        // accepts a jquery selector that should return array of 1 or more elements
        filterByOptionValues($('#filter-submit'));
    });
}
