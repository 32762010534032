/**
 * support for general conformity block
 */
export default class FloatingAccentBlocks {
    constructor() {
        // create each of the accent blocks
        TheCloroxCompany.ACCENT_BLOCKS.forEach((block) => {
            // get reference dom object pos
            const ref = document.getElementById(`custom-block-${block.reference_dom_object}`);
            if (!ref) {
                console.log(`custom-block-${block.reference_dom_object} not found`);
                return;
            }

            const size = TheCloroxCompany.ACCENT_BLOCK_SIZES[block.box_size];
            if (!size) {
                console.log(`invalid block size ${block.box_size}`);
                return;
            }

            const alignment = block.box_alignment || 'top';

            let accentContainer = ref.querySelector(`.floating-accent-container-${alignment}`);
            let accentWrapper;

            if (!accentContainer) {
                accentContainer = document.createElement('div');
                accentContainer.classList.add(`floating-accent-container-${alignment}`);
                accentContainer.style.width = '100vw';
                accentContainer.style.position = 'absolute';
                accentContainer.style.margin = '0 auto';
                accentContainer.style.left = '-50%';
                accentContainer.style.right = '-50%';

                accentWrapper = document.createElement('div');
                accentWrapper.classList.add('floating-accent-wrapper');
                accentWrapper.style.width = '100%';
                accentWrapper.style.position = 'relative';
                accentWrapper.style.margin = '0 auto';
                accentWrapper.style.maxWidth = '1593px';

                accentContainer.appendChild(accentWrapper);

                if (alignment === 'top') {
                    ref.prepend(accentContainer);
                } else {
                    ref.append(accentContainer);
                }
            } else {
                accentWrapper = accentContainer.querySelector('.floating-accent-wrapper');
            }

            const offset = parseInt(block.box_offset, 10);

            // create accent block
            const div = document.createElement('div');
            div.style.width = `${size.width}px`;
            div.style.height = `${size.height}px`;
            div.style.background = block.box_color;
            div.style.position = 'absolute';
            div.style.top = `${offset}px`;
            if (block.box_position === 'right') {
                div.style.right = 0;
            }
            div.classList.add('accent-block');
            div.classList.add(size.mobile ? 'mobile' : 'desktop');
            accentWrapper.appendChild(div);
        });
    }
}
