/*
 * Handle Ingredients Inside Filtering via dropdown and show more button
 */
export default function ingredientsPage() {
    $(document).ready(() => {
        // Update fragrance link.
        const links = {
            'separate list': '/brands/what-were-made-of/fragrances/',
            'lista separada': '/es-us-fragancias/',
            'liste séparée': '/fr-ca-ingredients-de-parfumerie/',
        };
        $('dd').each((event) => {
            const element = $(event.currentTarget);
            const phrases = ['separate list', 'lista separada', 'liste séparée'];
            $.each(phrases, (i, needle) => {
                let txt = element.html();
                const found = element.find(needle).length;
                const regex = new RegExp(`(${needle})(?![^(<a.*?>).]*?</a>)`, 'gi');
                if (found !== -1 && typeof txt !== 'undefined') {
                    txt = txt.replace(regex, `<a href="${links[needle]}">$1</a>`);
                    element.html(txt);
                }
            });
        });

        // fitlering dropdowns
        const baseUrl = '/brands/what-were-made-of/ingredients-inside/ingredients-brands-listing/';

        $('#region-brand-select').on('submit', (event) => {
            event.preventDefault();

            // extract the local and brand deets from the drowdoens
            const chosenLocale = $('select[name="region-select"]').val();
            const chosenBrand = $('select[name="brand-select"]').val().split(' ')[0];

            // redirect the page
            $(window.location).attr('href', `/${chosenLocale}${baseUrl}/${chosenBrand}`);
        });

        // show more pagination
        const allProductResultsRows = $('.hideshow_result tbody tr');
        const showMoreButton = $('#show-more-results');

        if (allProductResultsRows.length > 24) {
            // keep track of remaining items to show
            let remainingProductResults = $(allProductResultsRows).slice(24);

            // hide any rows after 25
            $(remainingProductResults).hide();

            // show more on click, and remain aware of what's remaining
            showMoreButton.on('click', (event) => {
                const element = event.currentTarget;
                $(remainingProductResults).slice(0, 24).show();
                remainingProductResults = $(remainingProductResults).slice(25);

                // hide the button if there are no more products
                if ($(remainingProductResults).length < 1) {
                    $(element).hide();
                }
            });
        } else {
            // hide the button since there aren't enough results
            $(showMoreButton).hide();
        }
    });
}
