import 'foundation-sites';
import 'slick-carousel';
import 'parsleyjs';
import Social from './components/social-share';
import ingredientsPage from './pages/ingredients';
import localeCookie from './pages/locale-cookie';
import mapPage from './pages/world-map';
import patentSelector from './pages/page-patents';
import pressPage from './pages/press-releases';
import sdsPage from './pages/page-sds';
import careers from './pages/careers';
import 'electro-privacy';

import './components/stock-ticker';
import YoutubeAPIModals from './components/youtube-api-modals';
import './components/responsive-tables';
import Sliders from './components/sliders';
import showMore from './components/show-more';
import SmoothScrolling from './components/smooth-scrolling';
import FloatingAccentBlocks from './components/floating-accent-blocks';

window.social = new Social();
// eslint-disable-next-line no-new
new SmoothScrolling();

const videos = document.querySelectorAll('[data-video-id]');
if (videos) {
    // eslint-disable-next-line no-new
    new YoutubeAPIModals();
}

// /////////////////////////////////////////////////////////////////////////////
// Routes
// /////////////////////////////////////////////////////////////////////////////

const path = window.location.pathname;

if (path === '/') {
    const sliders = new Sliders();
    sliders.HomeNewImagesSlider();
    sliders.HomeNewContentSlider();
} else if (
    path === '/news/blog/' ||
    path.match('/blog/') ||
    path.match('/newsroom/') ||
    path.match(/^\/brands\/disinfecting-lowdown.*/)
) {
    showMore(6, 6);
} else if (path === '/covid-19/') {
    showMore(6, 6);
} else if (path.match(/.*ingredients-inside\/.*/)) {
    localeCookie();
    ingredientsPage();
} else if (path === '/our-global-footprint/') {
    mapPage();
} else if (path === '/brands/patents/') {
    patentSelector();
} else if (path.match(/^\/news\/press-releases\//)) {
    pressPage();
} else if (path === '/brands/safety/sds/') {
    sdsPage();
} else if (
    path === '/careers/career-areas/' ||
    path === '/careers/us-careers/' ||
    path === '/careers/college-recruiting/' ||
    path === '/careers/international-careers/' ||
    path === '/careers/interns-recent-grads/' ||
    path === '/es-us-carreras/areas-de-carrera/' ||
    path === '/es-us-carreras/reclutamiento-en-la-universidad/'
) {
    careers();
}
// eslint-disable-next-line no-undef
if (TheCloroxCompany.ACCENT_BLOCKS) {
    // eslint-disable-next-line no-new
    new FloatingAccentBlocks();
}

// code from deprecated site-common:
/* global Foundation */

// Change ZF dropdown menu keyboard bindings
function setupNavigation() {
    Foundation.Keyboard.register('DropdownMenu', {
        ENTER: 'open',
        SPACE: 'open',
        ARROW_RIGHT: 'next',
        ARROW_UP: 'up',
        ARROW_DOWN: 'down',
        ARROW_LEFT: 'previous',
        ESCAPE: 'close',
    });
}

// Add custom mediaqueries for ZF interchange
// We want to change out images based on both dpi and size these enourmous
// strings are build from the OG ZF retina query
// http://foundation.zurb.com/sites/docs/interchange.html#named-media-queries
function setupInterchangeQueries() {
    Foundation.Interchange.SPECIAL_QUERIES.bigRetina =
        'only screen and (min-width: 769px) and (-webkit-min-device-pixel-ratio: 2),' +
        'only screen and (min-width: 769px) and (min--moz-device-pixel-ratio: 2),' +
        'only screen and (min-width: 769px) and (-o-min-device-pixel-ratio: 2/1),' +
        'only screen and (min-width: 769px) and (min-device-pixel-ratio: 2),' +
        'only screen and (min-width: 769px) and (min-resolution: 192dpi),' +
        'only screen and (min-width: 769px) and (min-resolution: 2dppx)';
    Foundation.Interchange.SPECIAL_QUERIES.smallRetina =
        'only screen and (max-width: 768px) and (-webkit-min-device-pixel-ratio: 2),' +
        'only screen and (max-width: 768px) and (min--moz-device-pixel-ratio: 2),' +
        'only screen and (max-width: 768px) and (-o-min-device-pixel-ratio: 2/1),' +
        'only screen and (max-width: 768px) and (min-device-pixel-ratio: 2),' +
        'only screen and (max-width: 768px) and (min-resolution: 192dpi),' +
        'only screen and (max-width: 768px) and (min-resolution: 2dppx)';
}

// Make an AJAX request for the regions for the selected country
function updateCountryRegions() {
    if ($('#dsar-form #country').val() && $('#dsar-form #country').val().length === 2) {
        const countryCode = $('#dsar-form #country').val();
        const data = { countryCode };

        const urlParams = new URLSearchParams(window.location.search);
        if (urlParams && urlParams.get('lang')) {
            data.lang = urlParams.get('lang');
        }

        $.ajax('/wp-json/tcc/v1/dsar/countryRegions/', {
            type: 'GET',
            data,
            timeout: 99000,
        })
            .done((response) => {
                if (response.data && response.data[0]) {
                    $('#state_or_province').empty().append('<option value=""></option>'); // require a non-empty selection

                    Object.keys(response.data[0]).forEach((key) => {
                        const value = response.data[0][key];
                        $('#state_or_province').append(`<option value="${key}">${value}</option>`);
                    });
                }
            })
            .fail((jqXHR, textStatus) => {
                console.log(`error: ${textStatus}`);
                $('#state_or_province').prop('required', false); // Waive requirement so form can be submitted
            });
    }
}

jQuery(document).ready(() => {
    setupInterchangeQueries();

    // init zurb foundation 6
    jQuery(document).foundation();

    setupNavigation();

    // toggle the animation on the mobile menu
    $('#nav-icon').click((event) => {
        const element = event.currentTarget;
        $('.is-drilldown-submenu.is-active').removeClass('is-active').addClass('invisible');
        $(element).toggleClass('open');
        $(document.body).toggleClass('scroll-blocker');
    });

    // have the return key open mobile nav
    $('.hamburger-icon').keypress((event) => {
        if (event.keyCode === 13 || event.keyCode === 32) {
            event.preventDefault();
            $('#nav-icon').click();
        }
    });

    // have the return key open search
    $('.search-icon').keypress((event) => {
        const element = event.currentTarget;
        if (event.keyCode === 13 || event.keyCode === 32) {
            event.preventDefault();
            $(element).click();
        }
    });

    // utility search input interaction
    $('#search-toggle-mobile').click((event) => {
        const src = $('#search-toggle-mobile').attr('src');
        const alt = $('#search-toggle-mobile').attr('alt');
        const newSrc = $('#search-toggle-mobile').attr('data-src');
        const newAlt = $('#search-toggle-mobile').attr('data-alt');
        $('#search-toggle-mobile').attr('src', newSrc);
        $('#search-toggle-mobile').attr('alt', newAlt);
        $('#search-toggle-mobile').attr('data-src', src);
        $('#search-toggle-mobile').attr('data-alt', alt);
        // toggles by sliding vertically
        $('#nav-search-area-mobile').slideToggle(500, () => {
            const element = event.currentTarget;
            // focus on the search text input automatically
            $(element).find('input[type=text]:first').focus();
        });
    });

    // mobile search interaction
    $('#search-toggle-utility').on('click', (event) => {
        const src = $('#search-toggle-utility').attr('src');
        const alt = $('#search-toggle-utility').attr('alt');
        const newSrc = $('#search-toggle-utility').attr('data-src');
        const newAlt = $('#search-toggle-utility').attr('data-alt');
        $('#search-toggle-utility').attr('src', newSrc);
        $('#search-toggle-utility').attr('alt', newAlt);
        $('#search-toggle-utility').attr('data-src', src);
        $('#search-toggle-utility').attr('data-alt', alt);
        // toggles by sliding horizontally
        $('#gcse-search-utility').animate({ width: 'toggle' }, 500, () => {
            const element = event.currentTarget;
            // focus on the search text input automatically
            $(element).find('input[type=text]:first').focus();
        });
    });

    // Elastic Custom Search Element
    $('.elastic-search-control').submit((event) => {
        event.preventDefault();
        const element = event.currentTarget;
        // extract the query text from the serialzed form
        const query = $(element).serializeArray()[0].value;
        // redirect to a URL
        window.location.href = `/search/?q=${query}`;
    });

    // a11y: auto focus elements in view area after folliwing a '#' link
    // this method fixes focus in legacy browsers, including some versions of
    // webkit that don't focus well with # links
    window.addEventListener(
        'hashchange',
        () => {
            const element = document.getElementById(window.location.hash.substring(1));
            if (element) {
                if (!/^(?:a|select|input|button|textarea)$/i.test(element.tagName)) {
                    element.tabIndex = -1;
                }
                element.focus();
            }
        },
        false
    );

    // override ZF6 align classes for last 2 nav dropdowns if they aren't Mega™
    $('#primary-menu > li').slice(-2).not('.mega').removeClass('opens-right').addClass('opens-left');

    // scroll events for collapsible nav
    if ($(window).width() > 767) {
        // $mq-lg-min
        let navScrollTimer;

        // bind to mouse events because they are more performant than `scroll`
        // in empirical studies
        $(window).on('scroll', () => {
            // debounce this method by bailing out if there is already a call pending
            clearTimeout(navScrollTimer);

            // wrap the effect in a timer
            navScrollTimer = setTimeout(() => {
                // console.log('Scrolled up');
                if ($(window).scrollTop() === 0) {
                    // we are at the top of the web page
                    $('.header-global').removeClass('collapsed');
                }
                // console.log('Scrolled down');
                if ($(window).scrollTop() > 0) {
                    // we have scrolled somewhere
                    $('.header-global').addClass('collapsed');
                }
            }, 250); // interval to wait before actually firing the effect
        });
    }

    // CCL Tab Menu active state
    if ($('.ccl-tab-menu').length) {
        const currentPath = window.location.pathname;
        const parentSlug = $('.ccl-tab-menu .ccl-menu').data('target');

        $('.ccl-tab-menu .ccl-menu a').each((i, element) => {
            const href = $(element).attr('href');
            if (parentSlug) {
                const splitPath = href.split('/');

                if (splitPath.indexOf(parentSlug) > -1) {
                    $(element).addClass('active menu-text');
                }
            } else if (href === currentPath || `${href}/` === currentPath || $(element).hasClass('force-active')) {
                $(element).addClass('active menu-text');
            }
        });
    }

    // Sustainability Read More
    if ($('.featured-areas__text .featured-readmore').length) {
        $('.featured-readmore').click((event) => {
            const element = event.currentTarget;
            $(element).toggleClass('active');
        });
    }

    // TCC Read More Accordion
    if ($('.tcc_readmore_wrap').length) {
        $('.tcc_readmore a').click((event) => {
            event.preventDefault();
            const element = event.currentTarget;

            $(element).hide().children('span').toggleClass('tcc_active');
            $(element).parent().siblings('.tcc_readmore_content').toggleClass('tcc_collapsed');
            setTimeout(() => {
                $(element).fadeIn('500');
            }, 500);

            if ($(element).parent().siblings('.tcc_readmore_content').hasClass('tcc_collapsed') === true) {
                setTimeout(() => {
                    $('html, body').animate(
                        {
                            scrollTop: $(element).parent('.tcc_readmore').offset().bottom,
                        },
                        500
                    );
                }, 2000);
            }
        });
    }

    // Slick slider init
    if ($('.tcc_article__slider.init-slider').length) {
        $('.tcc_article__slider .slide-wrap').slick({
            infinite: true,
            slidesToShow: 3,
            slidesToScroll: 1,
            centerMode: true,
            dots: true,
            responsive: [
                {
                    breakpoint: 767,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 1,
                        centerMode: false,
                    },
                },
                {
                    breakpoint: 480,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                        arrows: false,
                    },
                },
            ],
        });
    }

    // Slider for Scout article content block
    if ($('.scout-carousel').length) {
        $('.scout-carousel').slick({
            infinite: true,
            slidesToShow: 1,
            slidesToScroll: 1,
            centerMode: false,
            dots: true,
            // responsive: [
            //   {
            //     breakpoint: 767,
            //     settings: {
            //       slidesToShow: 1,
            //       slidesToScroll: 1,
            //       centerMode: false,
            //     },
            //   },
            //   {
            //     breakpoint: 480,
            //     settings: {
            //       slidesToShow: 1,
            //       slidesToScroll: 1,
            //       arrows: false,
            //     },
            //   },
            // ],
        });
    }

    // Accordion for Fragrances page

    if ($('.ingredient-lists').length) {
        $('.ingredient-lists h2').click((event) => {
            event.preventDefault();
            const element = event.currentTarget;

            if ($(element).parent().hasClass('active-mobile')) {
                $(element).parent().removeClass('active-mobile');
            } else {
                $('.ingredient-group').removeClass('active-mobile');
                $(element).parent().toggleClass('active-mobile');
            }
        });

        $('.accordion-button').click((event) => {
            event.preventDefault();
            const $element = $(event.currentTarget);
            const letters = $element.data('letters');

            if ($element.hasClass('active')) {
                $element.removeClass('active');
                $(`.group-${letters}`).removeClass('active');
            } else {
                // collapse any old items
                $('.accordion-button').removeClass('active');
                $('.ingredient-group').removeClass('active');

                // expand new group
                $element.addClass('active');
                $(`.group-${letters}`).addClass('active');
            }
        });
    }

    // A11y/accessibility
    // Add alt to images that are missing the attribute
    $('img:not([alt])').each((i, el) => {
        $(el).attr('alt', '');
    });

    // DSAR form parsley validation
    $('#dsar-form').parsley();

    // DSAR form 'other' request handling
    $('#dsar-form #request_type').on('change', () => {
        if ($(this).val() === 'other') {
            $('#request_type_other').show();
            $('#request_type_other').attr('data-parsley-required', 'true');
        } else {
            $('#request_type_other').hide();
            $('#request_type_other').attr('data-parsley-required', 'false');
        }
    });

    // User changed language for DSAR form
    $('#dsarlang').on('change', () => {
        window.location = `/privacy/dsar/?lang=${$('#dsarlang').val()}`;
    });

    if ($('#dsar-form #country').val() && $('#dsar-form #country').val().length === 2) {
        // Country geodetected on the server so dropdown has a value but no change event occurred.
        // Make an AJAX request to load regions for the geodetected country.
        updateCountryRegions();
    }

    // User selected a country on DSAR form. Load subregions
    $('#dsar-form #country').on('change', () => {
        updateCountryRegions();
    });
});

/** **********************************
 * Check for external links
 *********************************** */
Array.from(document.querySelectorAll('a')).forEach((a) => {
    a.classList.add(
        document.location.hostname === a.hostname ||
            !a.hostname.length ||
            a.hostname === 'investors.thecloroxcompany.com' ||
            a.hostname === 'cloroxir2016.s4.q4web.com'
            ? 'local'
            : 'external'
    );
});
$('a.external').attr('target', '_blank').attr('rel', 'external');

$(window).on('load', () => {
    const testimonialsCarousels = document.querySelectorAll('.testimonial-carousel');
    testimonialsCarousels.forEach((element) => {
        const sliders = new Sliders();
        const sliderWrapper = element.querySelector('.js-slider');
        sliders.testimonialCarousel(sliderWrapper);
    });

    const twoPanelSliders = document.querySelectorAll('.two-panel-slider ');
    twoPanelSliders.forEach((element) => {
        const sliders = new Sliders();
        const sliderWrapper = element.querySelector('.js-slider');
        sliders.twoPanelSlider(sliderWrapper);
    });
});
