export default function pressPage() {
    $(document).ready(() => {
        const $dropdowns = $('#news-search select');
        $dropdowns.on('change', () => {
            let year = $('#release-year').val();
            if (year === '') {
                year = 'all';
            }
            let category = $('#release-category').val();
            if (category === '') {
                category = 'all';
            }
            const url = `/news/press-releases/${year}/${category}/1/`;
            // Set location and let it go to history
            window.location = url;
        });

        $('#jump-to-page').on('change', (event) => {
            const element = event.currentTarget;

            let year = $('#release-year').val();
            if (year === '') {
                year = 'all';
            }

            let category = $('#release-category').val();
            if (category === '') {
                category = 'all';
            }

            const page = $(element).val();
            const url = `/news/press-releases/${year}/${category}/${page}/`;
            window.location = url;
        });
    });
}
