/*
 * Handles world map functionality.
 */
export default function mapPage() {
    $(document).ready(() => {
        const worldMap = {};
        worldMap.breakpoint = 753;

        // show all toggle-able content
        worldMap.showAll = () => {
            $('.marker__info').show();
        };

        // hide all toggle-able content
        worldMap.hideAll = () => {
            $('.marker__info').hide();
        };

        // disable all togglers
        worldMap.disableTogglers = () => {
            $('.marker__toggler', '.world-map').each((event) => {
                const element = event.currentTarget;
                const togglerTarget = $(element).data('toggle-focus');
                element.attr('data-toggle-focus-disabled', togglerTarget);
                element.removeAttr('data-toggle-focus');
            });
            // ensure all marker inner content is visible if user scales down viewport
            worldMap.showAll();
        };

        // re-enable all togglers after .disableTogglers()
        worldMap.enableTogglers = () => {
            $('.marker__toggler', '.world-map').each((event) => {
                const element = event.currentTarget;
                const togglerTarget = element.data('toggle-focus-disabled');
                element.attr('data-toggle-focus', togglerTarget);
                element.removeAttr('data-toggle-focus-disabled');
            });
            // ensure all marker inner content is hidden if user scales up viewport
            worldMap.hideAll();
        };

        // enable/disable ZF6 .callout toggles for based on viewport size
        worldMap.decideToggle = () => {
            if ($(window).width() < worldMap.breakpoint) {
                // console.info('togglers disabled');
                worldMap.disableTogglers();
            } else if ($(window).width() >= worldMap.breakpoint) {
                // console.info('togglers enabled');
                worldMap.enableTogglers();
            }
        };

        // shortcut to manually toggle a toggler
        worldMap.manualToggle = (el) => {
            if ($(el).is('.callout')) {
                $(el).foundation('toggle');
            } else {
                $(el).find('.callout').foundation('toggle');
            }
        };

        worldMap.init = () => {
            // make pseudo close button work as expected
            $('.marker__info').on('click', (event) => {
                const element = event.currentTarget;
                if ($(window).width() >= worldMap.breakpoint) {
                    worldMap.manualToggle($(element).parent());
                }
            });

            // disable/enable ZF6 .callout toggles as the viewport changes
            $(window).on('resize', () => {
                worldMap.decideToggle();
            });
        };

        worldMap.init();
    });
}
