/**
 * @method: Patent Selector
 * @author: James Billings
 * @Version: 1.0
 * */
export default function patentSelector() {
    $(document).ready(() => {
        $('.js-division-select').on('change', (event) => {
            event.preventDefault();
            const element = event.currentTarget;

            const { value } = element;

            $('.js-brand').each((i, brandEl) => {
                $(brandEl).show();
            });

            $('.js-division').each((i, divisionEl) => {
                $(divisionEl).hide();

                if ($(divisionEl).data('division') === value) {
                    $(divisionEl).show();
                    $(divisionEl).children('.division li').show();
                }
            });

            $('.js-brand-option').each((i, brandOptionEl) => {
                $(brandOptionEl).hide();

                if ($(brandOptionEl).data('division') === value) {
                    $(brandOptionEl).show();
                }
            });

            $('.js-brand-select').prop('selectedIndex', 0);
        });

        $('.js-brand-select').on('change', (event) => {
            event.preventDefault();
            const element = event.currentTarget;

            const { value } = element;

            $('.js-division').each((i, divisionEl) => {
                $(divisionEl).hide();
            });

            $('.js-brand').each((i, brandEl) => {
                $(brandEl).hide();

                if ($(brandEl).data('brand') === value) {
                    $(brandEl).show();
                    $(brandEl).parent().parent('.js-division').show();
                }
            });
        });
    });
}
