/*
 *  * Handles career pages support
 *
 * Used to create pretty URL's that look like
 *cr
 */

export default function careerPages() {
    $(document).ready(() => {
        // scroll to details
        const $header = $('.sticky-header-container');

        $('.js-jumplink').on('click', function (e) {
            e.preventDefault();
            $('html, body').animate(
                {
                    scrollTop: $($.attr(this, 'href')).offset().top - $header.height(),
                },
                500
            );

            return false;
        });

        // Open external links in new tab/window
        $('a').each(function () {
            const a = new RegExp(`/${window.location.host}/`);
            if (!a.test(this.href)) {
                $(this).click(function (event) {
                    event.preventDefault();
                    event.stopPropagation();
                    window.open(this.href, '_blank');
                });
            }
        });

        // read more expansion
        $('.js-read-more-toggle').on('click', function (e) {
            e.preventDefault();
            const $parent = $(this).closest('.box-container');
            $parent.find('.js-toggle-text').toggleClass('hide');
            $parent.find('.collapsible-content').toggleClass('collapsed');
            $parent.find('.toggle-dropdown').toggleClass('hide');
            if ($parent.find('.content__details.details--expanded').length > 0) {
                $parent.find('.content__details').toggleClass('hide');
            }
        });

        // campus event more info popup
        $('.js-campus-event-more-info').on('click', function (e) {
            e.preventDefault();
            $(this).parent('.event').find('.event-dropdown').toggleClass('collapsed');
            $(this).find('.js-toggle-text').toggleClass('hide');
        });
    });
}
