$(document).ready(() => {
    function setCellHeights(original, copy) {
        const tr = original.find('tr');
        const trCopy = copy.find('tr');
        const heights = [];

        tr.each((trIndex, trElement) => {
            const tx = $(trElement).find('th, td');

            tx.each((txIndex, txElement) => {
                const height = $(txElement).outerHeight(true);
                heights[txIndex] = heights[txIndex] || 0;
                if (height > heights[txIndex]) heights[txIndex] = height;
            });
        });

        trCopy.each((index, element) => {
            $(element).height(heights[index]);
        });
    }

    function splitTable(original) {
        original.wrap("<div class='table-wrapper' />");

        const copy = original.clone();
        copy.find('td:not(:first-child), th:not(:first-child)').css('display', 'none');
        copy.removeClass('responsive');

        original.closest('.table-wrapper').append(copy);
        copy.wrap("<div class='pinned' />");
        original.wrap("<div class='scrollable' />");

        setCellHeights(original, copy);
    }

    function unsplitTable(original) {
        original.closest('.table-wrapper').find('.pinned').remove();
        original.unwrap();
        original.unwrap();
    }

    let switched = false;
    const updateTables = () => {
        if ($(window).width() < 768 && !switched) {
            switched = true;
            $('table.responsive').each((i, element) => {
                splitTable($(element));
            });
            return true;
        }
        if (switched && $(window).width() > 768) {
            switched = false;
            $('table.responsive').each((i, element) => {
                unsplitTable($(element));
            });
        }
        return false;
    };

    $(window).on('load', updateTables);
    $(window).on('redraw', () => {
        switched = false;
        updateTables();
    }); // An event to listen for
    $(window).on('resize', updateTables);
});
