export default class Sliders {
    HomePageSlider() {
        $('.slider--homepage').slick({
            dots: true,
            arrows: true,
            infinite: true,
            autoplay: false,
            slidesToShow: 1,
            slidesToScroll: 1,
            adaptiveHeight: true,
            rows: false,
            nextArrow:
                '<button class="slick-next"><img src="/wp-content/themes/electro/img/svg/carat-right-white.svg" alt="next"><span class="visuallyhidden">Next Slide</span></button>',
            prevArrow:
                '<button class="slick-prev"><img src="/wp-content/themes/electro/img/svg/carat-left-white.svg" alt="prev"><span class="visuallyhidden">Previous Slide</span></button>',
        });
    }

    HomeNewImagesSlider() {
        $('.js-slider--homepage-images').slick({
            arrows: false,
            speed: 500,
            fade: true,
            rows: false,
            cssEase: 'linear',
            asNavFor: '.js-slider--homepage-content',
        });
    }

    HomeNewContentSlider() {
        $('.js-slider--homepage-content').slick({
            dots: true,
            autoplay: true,
            // If you want to update autoplaySpeed, does not forget to update
            // the animation duration for ".slick-active button" at section-page-home.scss
            autoplaySpeed: 7000,
            pauseOnHover: false,
            pauseOnFocus: false,
            rows: false,
            slidesToShow: 1,
            slidesToScroll: 1,
            speed: 500,
            fade: true,
            adaptiveHeight: true,
            cssEase: 'linear',
            asNavFor: '.js-slider--homepage-images',
            nextArrow:
                '<button class="slick-next"><svg viewBox="0 0 18 12"><path d="M11.5 0.179688L10.3281 1.35156L14.1562 5.17969H0.679688V6.82031H14.1562L10.3281 10.6484L11.5 11.8203L17.3203 6L11.5 0.179688Z" fill="white" /></svg><span class="visuallyhidden">Next Slide</span></button>',
            prevArrow:
                '<button class="slick-prev"><svg viewBox="0 0 18 12"><path d="M6.5 11.8203L7.67188 10.6484L3.84375 6.82031H17.3203V5.17969H3.84375L7.67188 1.35156L6.5 0.179688L0.679688 6L6.5 11.8203Z" fill="white" />/svg><span class="visuallyhidden">Previous Slide</span></button>',
        });
    }

    testimonialCarousel(carousel) {
        $(carousel).slick({
            dots: true,
            arrows: true,
            infinite: true,
            autoplay: true,
            autoplaySpeed: 6000,
            speed: 300,
            slidesToShow: 1,
            slidesToScroll: 1,
            adaptiveHeight: true,
            nextArrow: '<button type="button" class="slick-next"><img src="/wp-content/themes/electro/img/svg/carousel-arrow-east.svg"/><span class="visuallyhidden">Next Slide</span></button>',
            prevArrow: '<button type="button" class="slick-prev"><img src="/wp-content/themes/electro/img/svg/carousel-arrow-west.svg"/><span class="visuallyhidden">Previous Slide</span></button>',
        });
    }

    twoPanelSlider(carousel) {
        $(carousel).slick({
            dots: true,
            arrows: true,
            infinite: false,
            autoplay: false,
            speed: 300,
            slidesToShow: 1,
            slidesToScroll: 1,
            adaptiveHeight: true,
            nextArrow: '<button type="button" class="slick-next"><span class="visuallyhidden">Next Slide</span></button>',
            prevArrow: '<button type="button" class="slick-prev"><span class="visuallyhidden">Previous Slide</span></button>',
        });
    }
}
