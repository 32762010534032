// //////////////////////////////////////////////////////////////////////////////
// Youtube API and automatic modalization
// https://developers.google.com/youtube/iframe_api_reference
// //////////////////////////////////////////////////////////////////////////////

function loadYouTubeAPI() {
    // load the IFrame Player API code asynchronously
    if (typeof YT === 'undefined') {
        const tag = document.createElement('script');
        tag.src = '//www.youtube.com/iframe_api';
        tag.className = 'optanon-category-C0004';
        tag.type='text/plain';
        const firstScriptTag = document.getElementsByTagName('script')[0];
        firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
    }
}

function onPlayerReady(event) {
    const videoTitle = event.target.getVideoData().title;

    // populate markup with the video title
    $('#youtubeModalVideoTitle').append(videoTitle);
    $('#youtubeModalVideo').attr('title', videoTitle);
}

export default class YoutubeAPIModals {
    constructor() {
        if (typeof YT === 'undefined') {
            loadYouTubeAPI();
        }

        this.initListeners();
    }

    initListeners() {
        // this code creates gets a youtube id from the data attr in a reveal open link
        let youtubeVideoId;
        $('[data-video-id]').on('click', (event) => {
            // check if iframe is supported in the browser
            // this of course, rely's on javascript being enabled on your browser
            const iframeNotSupported = document.createElement('iframe');

            // fallback for iframe not supported
            if (!iframeNotSupported) {
                // convert modals into regular links
                let videoLink;
                $.each($('[data-video-id]'), (event) => {
                    const element = event.currentTarget;
                    videoLink = `https://www.youtube.com/watch?v=${$(element).data('video-id')}`;
                    $(element).removeAttr('data-open', videoLink);
                    $(element).attr('href', videoLink);
                    $(element).attr('target', '_blank');
                });
            } else if (typeof YT === 'undefined') {
                console.error('Check cookie settings');
                $('#youtubeModal .flex-video').html('<div class="consent-message">Please view <button class="ot-sdk-show-settings">Cookies Settings</button> to load content.</div>');
            } else {
                youtubeVideoId = $(event.currentTarget).data('video-id');

                $(document).on('open.zf.reveal', '#youtubeModal', () => {
                    // Create a new youtube iframe player instance based from the youtube id
                    // This is how youtube docs demo it
                    // eslint-disable-next-line no-undef, no-unused-vars
                    const player = new YT.Player('youtubeModalVideo', {
                        width: '560',
                        videoId: youtubeVideoId, // from `data-youtube-id` in the markup
                        host: 'https://www.youtube-nocookie.com',
                        playerVars: {
                            // https://developers.google.com/youtube/player_parameters
                            rel: 0,
                            theme: 'light',
                            showinfo: 0,
                            enablejsapi: 1,
                            showsearch: 0,
                            autoplay: 1,
                            autohide: 1,
                        },
                        events: {
                            // callbacks can go here, like:
                            onReady: onPlayerReady,
                            // 'onStateChange': onPlayerStateChange
                        },
                    });
                });

                // destroy the current video element when the modal is closed so it stops playing
                $(document).on('closed.zf.reveal', '#youtubeModal', () => {
                    $('#youtubeModalVideo').remove();
                    $('#youtubeModal .flex-video').append('<div id="youtubeModalVideo"></div>');
                });
            }
        });
    }
}
