/**
 * Shows more elements when a Show More button is clicked
 *
 *
 * @param {number} visible=6 - initial number of posts to be visible
 * @param {number} increment=6 - additional posts to show with each request
 * @returns {undefined}
 */

export default function showMore(visible, increment) {
    $(document).ready(() => {
        // all the teaser boxes
        const blogTeaserBoxes = $('.js-show-more-posts__item');
        // the current number of boxes
        const blogTeaserBoxCount = blogTeaserBoxes.length;
        // see more/all button
        const showButton = $('.js-show-more-posts__button');
        // local vars for main arguments
        let postsVisible = visible;
        const postsIncrement = increment;

        // hide posts we don't want yet
        $(blogTeaserBoxes.slice(postsVisible)).hide();

        // hide the button if we don't have more posts to show
        if (postsVisible >= blogTeaserBoxCount) {
            $(showButton).hide();
        }

        function showMorePosts() {
            // increment the number of posts that should be visible
            postsVisible += postsIncrement;

            // ensure that all posts that should be visible are visible
            $(blogTeaserBoxes.slice(0, postsVisible)).show();

            // are we nearing the end?
            if (postsVisible >= blogTeaserBoxCount) {
                // hide the button
                $(this).hide();
            }
        }

        showButton.on('click', showMorePosts);
    });
}
