/* ----------  Stock Ticker  ----------*/
// write stock info into markup from stock.twig which is called by shortcode
// [tcc_static id="stock"
// http://documentation.q4websystems.com/home/stock-quote-feed
// Timezone set to EST which conforms to stock market EOD

// custom formatting for date text
function formatStockDate() {
    // this actually outputs the same time in the user's local timezone,
    // we just want Date for it's string formatting
    const quoteDate = new Date();

    // split it old school, ES6 Date convenience methods are too new
    let quoteDateMonth = quoteDate.toString().split(' ')[1];
    // add abbreviation indication unless it's May
    if (quoteDateMonth.toLowerCase() !== 'may') {
        quoteDateMonth = `${quoteDateMonth}.`;
    }
    // get day
    let quoteDateDay = quoteDate.getDate();

    // pad the day value and convert to string
    if (quoteDateDay < 10) {
        quoteDateDay = `0${quoteDateDay}`;
    }
    // convert 24-hour to 12 and convert to EST with offset + 3 hours
    // eslint-disable-next-line no-mixed-operators
    const quoteDateHour = ((quoteDate.getHours() + 11) % 12) + 1 + 3;
    // get minute
    let quoteDateMinute = quoteDate.getMinutes();
    // pad the Minute value and convert to string
    if (quoteDateMinute < 10) {
        quoteDateMinute = `0${quoteDateMinute}`;
    }
    // determine am/pm
    let quoteDateMeridiem = 'AM';
    if (quoteDate.getHours() >= 12) {
        quoteDateMeridiem = 'PM';
    }
    // put it together
    const quoteDateFormatted = `${quoteDateMonth} ${quoteDateDay}, ${quoteDateHour}:${quoteDateMinute} ${quoteDateMeridiem}`;

    return quoteDateFormatted;
}

// error handler for api response
function OnLoadError(response) {
    // eslint-disable-next-line no-console
    console.log('could not retrieve CLX stock data from Q4', response);
}

// success handler for api response
function OnQuoteLoadSuccess(response) {
    // get the first index (stock)
    const quoteData = response.GetStockQuoteListResult[0];
    // build markup string for final output
    const stockMarkup =
        // Aug. 12, 1:43 pm ET      CLX: $133.36, +0.16 (0.12%)
        // formatStockDate('09/11/2017 16:02:00')
        `<span>Volume: ${quoteData.Volume}</span>
    <span class="stock-date">
      ${formatStockDate(quoteData.TradeDate)} ET &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
    </span>
    <span class="stock-price">
      CLX: $${quoteData.TradePrice},
      ${quoteData.Change}&nbsp;(${quoteData.PercChange.toFixed(2)}%)
    </span>`;
    // append to stock ticker element using our custom formatting infrastructure

    $('.js-stock-TradeDate').text(formatStockDate(quoteData.TradeDate));
    $('.js-stock-TradePrice').text(quoteData.TradePrice);
    $('.js-stock-Change').text(quoteData.Change > 0 ? `+${quoteData.Change}` : quoteData.Change);
    $('.js-stock-PercChange').text(quoteData.PercChange.toFixed(2));
    $('.js-stock-Volume').text(new Intl.NumberFormat().format(quoteData.Volume));
    $('.stock-ticker').append(stockMarkup);
}
if ($('.stock-ticker')) {
    // set request params for Q4.ajax
    const Q4StockParams = {
        apiKey: '31C3870B5EB2437F81C257875228741F',
        exchange: 'NYSE',
        symbol: 'CLX',
        pageSize: '1',
        // endDate: "2011/06/10 15:30:00",
        // startDate: "2011/06/10 07:30:00"
    };

    // call Q4 via ajax
    $.ajax({
        type: 'GET',
        url: 'https://cloroxir2016.q4web.com/feed/StockQuote.svc/GetStockQuoteList',
        data: Q4StockParams,
        dataType: 'json',
        error: OnLoadError,
        success: OnQuoteLoadSuccess,
    });
}
