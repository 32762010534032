/*
 * Set a global locale cookie that helps persist language/region preference
 * across the site.
 */
export default function localeCookie() {
    const hasCookies = () => navigator.cookieEnabled;

    $(document).ready(() => {
        if (!hasCookies()) {
            // eslint-disable-next-line no-alert
            alert(
                'Please enable cookies in your browser. Site functionality will ' + 'affected if cookies are disabled.'
            );
        }
        $('.button').on('click', () => {
            // add "locale" cookie
            const url = window.location.pathname;
            const split = url.split('/');
            // grab locale if its in the pathhname
            const foundLocale = split[1].match('(/[a-z]{2}-[a-z]{2})/g');
            if (foundLocale != null) {
                const re = /(?:(?:^|.*;\s*)ingredientslocale \s*=\s*([^;]*).*$)|^.*$/;
                if (document.cookie.replace(re, '$1') !== 'true') {
                    document.cookie = `locale=${foundLocale}expires=60*60*24*15;`;
                    document.cookie = 'hidePoll=true; expires=60*60*24*15;';
                }
            }
        });
    });
}
