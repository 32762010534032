// Different Types of Methods
// 1. Actions: Methods that fetch data and populate state
// 2. Handlers: Methods that handle events (prefixed with 'handle')
// 3. Renderers: Methods that render views (prefixed with 'render')
//    a. Render is purely working off of the state.
//    b. Render should decide which template to render AND pass a view model (aka context) to the template.

export default class SmoothScrolling {
    constructor() {
        console.info('SmoothScrolling.constructor()');

        this.initListenters();
    }

    initListenters() {
        $('a').on('click', function (event) {
            // Make sure this.hash has a value before overriding default behavior
            if (this.hash !== '' && !$(this).hasClass('external') && !$(this).hasClass('internal')) {
                // make sure it is going to this page
                if (this.pathname !== window.location.pathname) {
                    return;
                }

                // Prevent default anchor click behavior
                event.preventDefault();

                console.info('hash: ', this.hash);

                // Store hash
                const { hash } = this;

                // Using jQuery's animate() method to add smooth page scroll
                $('html, body').animate(
                    {
                        scrollTop: $(hash).offset().top,
                    },
                    1000,
                    () => {
                        // Add hash (#) to URL when done scrolling (default click behavior)
                        window.location.hash = hash;
                    }
                );
            } // End if
        });
    }
}
